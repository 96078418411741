import { useEffect } from "react";
import { useBanner } from "..";
/**
 * Do not allow scrolling when this banner is visible and add a blur effect if enabled.
 */

function useRestrictScrollWhenVisible(show) {
  const banner = useBanner();
  const {
    layout: {
      overlay,
      overlayBlur
    },
    pageRequestUuid4,
    animationVisible
  } = banner;
  const showOverlay = show && overlay && animationVisible;
  const bodyClassRcbOverlayActive = "rcb-overlay-active"; // Blur effect

  if (process.env.PLUGIN_CTX === "pro") {
    /* Silence is golden... Until you have PRO Version! */
  }

  return useEffect(() => {
    const {
      dataset,
      style,
      classList
    } = document.body; // Save first state

    if (dataset.rcbPreviousOverflow === undefined) {
      dataset.rcbPreviousOverflow = style.overflow;
    }

    style.overflow = showOverlay ? "hidden" : dataset.rcbPreviousOverflow;
    document.body.parentElement.style.overflow = style.overflow;

    if (showOverlay) {
      classList.add(bodyClassRcbOverlayActive);
    } else {
      classList.remove(bodyClassRcbOverlayActive);
    }
  }, [showOverlay]);
}

export { useRestrictScrollWhenVisible };